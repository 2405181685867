import { LiveConcert, PublicationLevel, Video, VodConcert } from 'generated/graphql';
import { Badge } from 'src/components/common/badge';
import useTicketStatus from 'src/hooks/use-ticket-status';
import useTranslate from 'src/hooks/use-translate';

type NodeFreeBadgeProps = {
  node: Pick<Video | VodConcert | LiveConcert, '__typename' | 'publicationLevel'>;
};

/**
 * Renders a free badge for a video that is free to watch and non-subscribed users.
 */
export function NodeFreeBadge({ node }: NodeFreeBadgeProps) {
  const t = useTranslate();
  const { isActive } = useTicketStatus();
  // A free video is one that does not require a ticket
  const isFree = node.publicationLevel !== PublicationLevel.TicketRequired;

  // Only show the free badge if the user is not subscribed and the video is free
  if (!isActive && isFree) {
    return <Badge small>{t('component__badge_free')}</Badge>;
  }

  return null;
}
