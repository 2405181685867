import clsx from 'clsx';
import { LiveConcert } from 'generated/graphql';
import useHasMounted from 'src/hooks/use-has-mounted';
import useIntl from 'src/hooks/use-intl';
import useLiveConcertState from 'src/hooks/use-live-concert-state';
import useTranslate from 'src/hooks/use-translate';
import { differenceInMinutes } from 'src/utilities/date-helpers';
import { liveConcertTimeFormat } from 'src/utilities/live-concert-helpers';

type LiveConcertTimeProps = {
  liveConcert: Pick<LiveConcert, 'endTime' | 'startTime' | 'streamStartTime' | 'reruns'>;
};

/**
 * Renders the live concert time based on the current time and the live concert start time.
 * When the live concert is in the past, it renders "Available soon in the archive".
 * When the live concert is in the future, it renders the start time.
 * If the start time is less than 1 hour from now, return "In m min" (e.g. In 59 min)
 * If the start time is the same day, return "Today, HH:mm" (e.g. Today, 19:00)
 * If the start time is the next day, return "Tomorrow, HH:mm" (e.g. Tomorrow, 19:00)
 * If the start time is less than a week from now, return "Wkd, HH:mm" (e.g. Fri, 19:00)
 * Otherwise, return "MM/dd Wkd, HH:mm" (e.g. 04/18 Fri, 19:00)
 */
export function LiveConcertTime({ liveConcert }: LiveConcertTimeProps) {
  const t = useTranslate();
  const hasMounted = useHasMounted();
  const { locale } = useIntl();
  const { status, startTime } = useLiveConcertState(liveConcert);
  const startDate = startTime ? new Date(startTime) : undefined;

  // Only render this component on the client because the server can have a different time zone than the client
  if (!hasMounted) return null;

  if (status === 'past') return <span data-test="live-concert-time">{t('live_concert__status_label_past')}</span>;

  if (!startDate || status === 'live') return null;

  const formattedTime = liveConcertTimeFormat(startDate, locale);
  const isLessThanOneHour = differenceInMinutes(startDate) < 60;

  if (!formattedTime) return null;

  return (
    <time
      dateTime={startDate.toISOString()}
      className={clsx('lining-nums', { 'text-support-live': isLessThanOneHour })}
      data-test="live-concert-time"
    >
      {formattedTime}
    </time>
  );
}
